.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
    position: relative
}

.team-stats {
    max-width: 1200px;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.average-stats-container {
    border: 1px solid var(--lighter-background-color);
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    max-height: 400px;
    margin-top: 5px;
    padding: 0;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    justify-content: space-around; /* evenly distribute items along the line */
    flex-wrap: wrap;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* animate border and shadow changes */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    padding: 0px;
}

.match-stats-container {
    border: 1px solid var(--lighter-background-color);
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    max-height: 250px;
    margin-top: 5px;
    padding: 0;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    justify-content: space-around; /* evenly distribute items along the line */
    flex-wrap: wrap;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* animate border and shadow changes */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    padding: 0px;
}

.match-content-selector {
    width: 200px;
    display: flex;
    margin: 0 auto;
    align-items: center;
}

.selector {
    outline: none;
    height: 30px;
    width: 100px;
    margin: 0 auto;
    border-radius: 15px;
    text-align: center;
}

.radar {
    font-size: 12px;
    transition: transform 0.25s;
}

.radar:hover {
    transform: scale(1.1);
}

.team-stat-header {
    font-size: 40px;
}

.team-average-header {
    font-size: 25px;
}

.team-match-header {
    font-size: 25px;
}