.settings-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    height: 30px;
    width: 70px;
    background-color: #3b7f37;
    display: flex;
    align-items: center;
    border-radius: 20px;
    z-index: 2000000;
    user-select: none;
}

.settings-button:hover {
    cursor: pointer;
}

.settings-button-text {
    margin: 0 auto;
}

.settings-img {
    height: 40px;
}

.settings-window {
    position: fixed;
    left: 0; 
    right: 0;
    top: 0;
    bottom: 0; 
    margin: auto;
    width: 300px;
    height: 300px;
    background-color: rgb(45, 45, 45);
    border-style: solid;
    border-color: white;
    z-index: 2000;
    border-radius: 20px;
    padding: 20px;
}

.setting-card {
    height: 40px;
    margin-bottom: 5px;
    background-color: rgb(60, 60, 60);
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    border-radius: 20px;
}

.setting-current,
.setting-name,
.change-button {
    display: flex;
    align-items: center;
    width: 100%;
}

.settings-text {
    margin: 0 auto;
}

.change-button {
    background-color: #3b7f37;
    height: 30px;
    width: 80%;
    border-radius: 15px;
    margin: 0 auto;
    user-select: none;
}

.change-button:hover {
    cursor: pointer;
}