.bar-chart {
    width: 100%;
    height: 400;
    display: flex;
    justify-content: center;
}

.search-bar-compare {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
}

.search-bar-compare input {
    width: 200px;
    height: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.search-bar-compare button {
    height: 30px;
    padding: 5px 10px;
    margin-left: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.search-bar-compare button:hover {
    background-color: #0056b3;
}

.team-stats-compare {
    width: 1200px;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.match-content-selector {
    width: 200px;
    display: flex;
    margin: 0 auto;
    align-items: center;
}

.selector {
    outline: none;
    height: 30px;
    width: 100px;
    margin: 0 auto;
    border-radius: 15px;
    text-align: center;
}

.radar-ct {
    font-size: 12px;
}

.radar-many {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);

}

.team-stat-header {
    font-size: 40px;
}

.team-average-header {
    font-size: 25px;
}

.team-match-header {
    font-size: 25px;
}